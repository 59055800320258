import { useSetAtom } from "jotai";
import { useAtom } from "jotai";
import snakeLogo from "../assets/dragon_icon_small_.png";
import { isModalOpenAtom, isBuyModalOpenAtom, isClaimModalOpenAtom, isTransferModalOpenAtom, isHistoryModalOpenAtom, isGuideModalOpenAtom } from "../store/modal";
import loka_icon from "../assets/dragon_icon_.png";
import wallet_icon from "../assets/wallet.svg";
import { FaXTwitter } from "react-icons/fa6";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useMediaQuery } from "react-responsive";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier } from "@dfinity/ledger-icp";
import { Layout, Row, Col, Button, theme } from "antd";
import Icon, { PoweroffOutlined, CloseOutlined, GoogleOutlined } from "@ant-design/icons";
import "./style.css";
const IconConnect = () => (
  <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 0.833375H5.83333C3.53214 0.833375 1.66666 2.69886 1.66666 5.00004C1.66666 7.30123 3.53214 9.16671 5.83333 9.16671H7.5C9.80118 9.16671 11.6667 7.30123 11.6667 5.00004M13.75 9.16671H14.1667C16.4679 9.16671 18.3333 7.30123 18.3333 5.00004C18.3333 2.69886 16.4679 0.833374 14.1667 0.833374H12.5C10.1988 0.833374 8.33333 2.69886 8.33333 5.00004"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default function Header() {
  const [isButtonOpen, setIsButtonOpen] = useState(false);
  const [accountId, setAccountid] = useState("");
  const toggleAccordion = () => {
    setIsButtonOpen(!isButtonOpen);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [collapsed, setCollapsed] = useState(false);

  const { loginInstance, setCanisterActor, canisterActor, setGameData, gameData, setUserData, walletAddress, setWalletAddress, userData, eyesLedger, eyesBalance, setEyesBalance, toggleMobileMenu, setToggleMobileMenu, setWalletAlias } =
    useContext(AppContext);
  const [isBuyModalOpen, setBuyModalOpen] = useAtom(isBuyModalOpenAtom);
  const [isClaimModalOpen, setClaimModalOpen] = useAtom(isClaimModalOpenAtom);
  const [isGuideModalOpen, setGuideModalOpen] = useAtom(isGuideModalOpenAtom);
  const [isTransferModalOpen, setTransferModalOpen] = useAtom(isTransferModalOpenAtom);
  const [isHistoryModalOpen, setHistoryModalOpen] = useAtom(isHistoryModalOpenAtom);
  const [userEyes, setUserEyes] = useState(false);
  const setModalOpen = useSetAtom(isModalOpenAtom);
  const handleLogout = async () => {
    //setLoading(true);
    await loginInstance.logout();
    setCanisterActor();
    setUserData(false);
    //setGameData(false);
    setWalletAddress(false);
    setToggleMobileMenu(false);
    //setLoading(false);
  };
  const collapse = () => {
    setToggleMobileMenu(!toggleMobileMenu);
  };
  const getTicketPurchase = async () => {
    var n = await canisterActor.getTicketPurchaseHash();
  };
  const getEyesBalance = async () => {
    var acc = {
      owner: Principal.fromText(walletAddress),
      subaccount: [],
    };
    var balance_ = await eyesLedger.icrc1_balance_of(acc);
    var n_ = Number(balance_) / 100000000;
    n_ = parseFloat(n_).toLocaleString();
    setEyesBalance(n_);
  };

  useEffect(() => {
    if (walletAddress && eyesLedger) {
      var acc = {
        principal: Principal.fromText(walletAddress),
        subaccount: [],
      };
      var accid = AccountIdentifier.fromPrincipal(acc);

      setAccountid(accid.toHex());
      getEyesBalance();
    }
  }, [eyesLedger, walletAddress]);
  useEffect(() => {}, [toggleMobileMenu]);
  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return str.substr(0, frontChars) + "..." + str.substr(str.length - backChars);
  }

  // Copy to Clipboard Function
  function copyToClipboard() {
    //const textToCopy = walletAddress; // Replace with your actual string variable
    const textToCopy = accountId;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Wallet address copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  function copyToClipboardW() {
    const textToCopy = walletAddress; // Replace with your actual string variable
    // textToCopy = accountId;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Principal copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  useEffect(() => {
    let timer;

    if (isButtonOpen) {
      // If the accordion is open, set a timer to close it after 3 seconds
      timer = setTimeout(() => {
        setIsButtonOpen(false);
      }, 3000);
    }

    return () => {
      // Clear the timer when the component unmounts or the state changes
      clearTimeout(timer);
    };
  }, [isButtonOpen]);
  return (
    <nav className="grid w-full z-99999 ">
      <div className="bg-[#EE5151] w-full h-14 lg:h-20 px-5 lg:px-20 flex justify-center items-center text-sm lg:text-base">
        <div className="w-full max-w-7xl flex items-center justify-between">
          <div className="w-8 lg:w-12">
            <a href="https://dragoneyes.xyz" target="blank">
              <img src={snakeLogo} className="w-full h-full object-cover" />
            </a>
          </div>

          {isDesktopOrLaptop ? (
            <div className="flex items-center gap-5 font-semibold text-white">
              {walletAddress ? (
                <>
                  {/*<a className="py-1 px-2 lg:py-2.5 lg:px-4" href="/migrate">
                    Migration
                  </a>*/}
                  <a
                    className="py-1 px-2 lg:py-2.5 lg:px-4"
                    onClick={() => {
                      setBuyModalOpen(true);
                    }}
                  >
                    Buy Ticket
                  </a>
                </>
              ) : (
                <></>
              )}{" "}
              <a
                className="py-1 px-2 lg:py-2.5 lg:px-4"
                onClick={() => {
                  setGuideModalOpen(true);
                }}
              >
                How To Play?
              </a>
              {walletAddress ? (
                <>
                  {" "}
                  <a
                    className="py-1 px-2 lg:py-2.5 lg:px-4"
                    onClick={() => {
                      if (userData) setClaimModalOpen(true);
                    }}
                  >
                    Claim
                  </a>
                  {/*<a
                    className="py-1 px-2 lg:py-2.5 lg:px-4"
                    onClick={() => {
                      if (userData) setTransferModalOpen(true);
                    }}
                  >
                    Withdraw
                  </a> */}
                  <a
                    className="py-1 px-2 lg:py-2.5 lg:px-4"
                    onClick={() => {
                      if (userData) setHistoryModalOpen(true);
                    }}
                  >
                    Stats
                  </a>
                </>
              ) : (
                <></>
              )}
              <div className="flex">
                <a href="https://x.com/icdragoneyes" target="blank">
                  <FaXTwitter className="h-6 w-6" />
                </a>
              </div>
              <div className="px-4 flex">
                <a href="https://docs.dragoneyes.xyz" target="blank">
                  Docs
                </a>
              </div>
              {walletAddress ? (
                <div style={{ position: "relative" }} className="items-center text-center relative">
                  {" "}
                  <div
                    onClick={() => {
                      if (userData) setTransferModalOpen(true);
                    }}
                    className="cursor-pointer max-h-48 lg:flex overflow-hidden transition-max-h bg-[#1E3557] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg"
                  >
                    <img src={wallet_icon} className="w-[20px] mr-2"></img>
                    Wallet
                  </div>
                  {/*<button
                    onClick={() => {
                      //handleLogout();
                      toggleAccordion();
                    }}
                    className="flex w-full justify-between text-[#1E3557] bg-[#F5F5F5] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg "
                  >
                    {truncateString(accountId, 8)}
                    <span
                      className="cursor-pointer px-2"
                      onClick={() => {
                        copyToClipboard();
                      }}
                    >
                      📋
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        copyToClipboardW();
                      }}
                    >
                      📋
                    </span>
                  </button>

                  <button
                    onClick={() => {
                      handleLogout();
                      //toggleAccordion();
                    }}
                    style={{
                      position: "absolute",
                      top: isButtonOpen ? "100%" : "0",
                      left: 0,
                      right: 0,
                      opacity: isButtonOpen ? 1 : 0,
                      pointerEvents: isButtonOpen ? "auto" : "none",
                      transition: "top 0.3s, opacity 0.3s",
                    }}
                    className={`accordion-content ${
                      isButtonOpen
                        ? "max-h-48 overflow-hidden transition-max-h bg-[#1E3557] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg"
                        : "max-h-0 bg-[#1E3557] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg"
                    }`}
                  >
                    Disconnect{"  "} <PoweroffOutlined />
                  </button> */}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="bg-[#1E3557] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg"
                >
                  Connect wallet {"  "} <Icon component={IconConnect} />
                </button>
              )}
            </div>
          ) : (
            <div className="flex items-center gap-3 font-semibold right-0">
              <Button
                type="text"
                className="toogle-collapse-button"
                icon={toggleMobileMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => collapse()}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                  color: "white",
                }}
              />
            </div>
          )}
        </div>
      </div>

      {toggleMobileMenu && !isDesktopOrLaptop ? (
        <div className="h-[100vh] text-white bg-warm-white bg-opacity-90 z-50 p-5 text-center justify-center transition duration-300 ease-in-out">
          <div className="grid w-full items-center text-center gap-3 font-semibold text-[#1E3557]">
            {walletAddress ? (
              <>
                {/*<a className="py-1 px-2 lg:py-2.5 lg:px-4" href="/migrate">
                    Migration
                  </a>*/}
                <a
                  className="py-1 px-2 lg:py-2.5 lg:px-4"
                  onClick={() => {
                    setBuyModalOpen(true);
                  }}
                >
                  Buy Ticket
                </a>
              </>
            ) : (
              <></>
            )}
            <a
              className="py-1 px-2 lg:py-2.5 lg:px-4"
              onClick={() => {
                setGuideModalOpen(true);
              }}
            >
              How To Play?
            </a>
            {walletAddress ? (
              <>
                {" "}
                <a
                  className="py-1 px-2 lg:py-2.5 lg:px-4"
                  onClick={() => {
                    if (userData) setClaimModalOpen(true);
                  }}
                >
                  Claim
                </a>
                {/*<a
                  className="py-1 px-2 lg:py-2.5 lg:px-4"
                  onClick={() => {
                    if (userData) setTransferModalOpen(true);
                  }}
                >
                  Withdraw
                </a> */}
                <a
                  className="py-1 px-2 lg:py-2.5 lg:px-4"
                  onClick={() => {
                    if (userData) setHistoryModalOpen(true);
                  }}
                >
                  Stats
                </a>
              </>
            ) : (
              <></>
            )}
            <div className="grid w-full text-center items-center justify-center">
              <a href="https://x.com/icdragoneyes" target="blank" className="py-1 px-2 lg:py-2.5 lg:px-4">
                <FaXTwitter className="h-6 w-6" />
              </a>

              <a href="https://docs.dragoneyes.xyz" target="blank">
                Docs
              </a>
            </div>
            <div className="items-center text-center justify-center w-full">
              {walletAddress ? (
                <div
                  onClick={() => {
                    if (userData) setTransferModalOpen(true);
                  }}
                  className="w-full text-center justify-center flex items-center"
                >
                  <div className="cursor-pointer flex w-[100px]  justify-center items-center text-center  bg-[#1E3557] text-warm-white  py-2 rounded-lg">
                    <img src={wallet_icon} className="w-[20px] mx-2"></img>
                    <div>Wallet</div>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="bg-[#1E3557] text-white py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg"
                >
                  Connect wallet {"  "} <Icon component={IconConnect} />
                </button>
              )}
              <div className="grid text-center items-center justify-center mt-10">Dragon Eyes @ 2024</div>{" "}
              <div className="w-full items-center justify-center text-center flex">
                <a href="https://dragoneyes.xyz" target="blank">
                  <img src={loka_icon} className="w-[30px]  "></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
}
